import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";


const {useRef, useState, useEffect} = React;
const LazyImage = ({src, alt, title, address, pin}) => {
    const rootRef = useRef();

    const [isVisible, setIsVisible] = useState(false);
    const loading = 'https://www.tekrevol.com/Tek-Spinner.gif';

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
        };

        const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <div className="location_item">
            <h4>{title}</h4>
            <img src={`https://tekrevol.tekstaging.com/storage/app/media${isVisible ? src : loading}`} ref={rootRef}
                 className="img-fluid" alt={alt}/>
            <div className="addr">{address}</div>
            <Link to={pin} target="_blank">View Location</Link>
        </div>

    );
};


function Location({data}) {

    return (
        <div className="tekrevol_location">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="mt-auto mb-auto">
                        <div className="loaction_box">
                            {data ? data.map((el, i) => (
                                <LazyImage
                                    alt={el.place_name}
                                    src={el.place_image} key={i} title={el.place_name} address={el.address}
                                    pin={el.place_link}

                                />
                            )) : <p>No Data found</p>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Location;